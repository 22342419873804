<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      max-width="1020px"
      @click:outside="closeWatchPresentationModal(false)"
    >
      <v-card class="pb-2">
        <v-container>
          <v-card-text>
            <div class="contenedor">
              <div class="informacion-principal">
                <div class="informacion-expositor">
                  <div class="contenedor-expositor">
                    <div class="informacion-nombre">
                      <p class="my-text-secondary">Expositor</p>
                      <p class="my-text-primary">
                        {{ this.presentation.speaker.name }}
                      </p>
                    </div>
                    <div class="item-1">
                      <v-icon dense>mdi-email</v-icon>
                      <p class="my-text-sub-secondary">
                        {{ this.presentation.speaker.email }}
                      </p>
                    </div>
                    <div class="item-informacion-expositor">
                      <v-icon dense>mdi-account</v-icon>
                      <p>Asesor Interno</p>
                    </div>
                  </div>
                </div>
                <div class="informacion-inmobilaria">
                  <p class="my-text-primary">{{ this.presentation.title }}</p>
                  <div class="contenedor-grids-informacion">
                    <div class="contenedor-creado">
                      <p class="text-creado">Creado el</p>
                      <p class="text-fecha">
                        {{ this.presentation.created_at | dateFormat(true) }}
                      </p>
                    </div>
                    <div class="contenedor-realizado">
                      <p class="text-creado">Realizado el</p>
                      <p class="text-fecha">
                        {{
                          this.presentation.date | dateFormatPresentation(true)
                        }}
                      </p>
                    </div>
                    <div class="contenedor-from">
                      <p class="my-text-secondary">Desarrollo</p>
                      <div class="item-informacion-inmobilaria">
                        <p class="my-text-sub-secondary">
                          {{ this.presentation.real_state_development_name }}
                        </p>
                        <br />
                        <p>Ubicación de la presentación:</p>
                        <p>{{ this.presentation.location }}</p>
                      </div>
                    </div>
                    <div class="contenedor-to">
                      <p class="my-text-secondary">Inmobiliaria</p>
                      <div class="item-informacion-inmobilaria">
                        <p class="my-text-sub-secondary">
                          {{ this.presentation.to_real_estate_group_name }}
                        </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="informacion-descripcion mb-5">
                <div>
                  <p class="my-text-primary">Comentario</p>
                  <div class="contenedor-descripcion">
                    <div class="body-descripcion">
                      <p>
                        {{ this.presentation.comment }}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="my-text-primary">Metodo de presentación</p>
                  <div class="contenedor-descripcion">
                    <div class="body-descripcion">
                      <p>
                        {{ this.presentation.typePresentation }}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="my-text-primary">Audiencia</p>
                  <div class="contenedor-descripcion">
                    <div class="mt-1">
                      <v-chip
                        v-for="part in this.presentation.participating"
                        :key="part._id"
                        class="ma-1"
                        color="primary"
                        text-color="white"
                      >
                        <v-icon left>mdi-account-circle</v-icon>
                        {{ part.real_estate_group_list[0].name }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-img">
                <v-img
                  v-if="this.presentation.image !== ''"
                  :src="this.presentation.image"
                ></v-img>
              </div>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWatchPresentationModal()">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
moment.locale("es");
export default {
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
    };
    return {
      items: [
        {
          action: "mdi-account-multiple-plus",
          items: [
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] }
          ],
          title: "Asistencias"
        },
        {
          action: "mdi-presentation",
          items: [
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] }
          ],
          title: "Expositores"
        }
      ],
      messages: [
        {
          from: "Admin Propiedades Tulum",
          message: `Favor de subir imagen de evidencia`,
          time: "10:42am",
          color: "deep-purple lighten-1"
        },
        {
          from: "Asesor Luis",
          message: "Evidencia subida",
          time: "10:37am",
          color: "green"
        }
      ],
      loadPresentation: true
    };
  },
  props: {
    propsShowModalWatch: {
      type: Boolean,
      default: false
    },
    presentationToWatchId: {
      type: String,
      required: false,
      default: null
    }
  },
  watch: {
    async propsShowModalWatch() {
      if (this.propsShowModalWatch) {
        if (this.presentationToWatchId) {
          await this.getPresentationById(this.presentationToWatchId);
        }
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.propsShowModalWatch;
      },
      set(value) {
        if (!value) {
          this.$emit("closeWatch");
        }
      }
    },
    timeline() {
      return this.events.slice().reverse();
    },
    ...mapGetters({
      presentation: "presentation/presentation"
    })
  },
  methods: {
    ...mapActions({
      getPresentationById: "presentation/getPresentationById"
    }),
    closeWatchPresentationModal() {
      this.show = false;
    },
    imgContact(presentation) {
      const nameContact = presentation.speaker.name;
      if (presentation.presentation_contact_admin) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_admin,
          nameContact
        );
        return imagenContact;
      }
      if (presentation.presentation_contact_broker) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_broker,
          nameContact
        );
        return imagenContact;
      }
      if (presentation.presentation_contact_developer) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_developer,
          nameContact
        );
        return imagenContact;
      }
      return this.createDummyImgFormat(nameContact);
    },
    validarImagen(presentationContact, nameContact) {
      if (presentationContact.media) {
        if (presentationContact.media.featured_image) {
          if (presentationContact.media.featured_image.src) {
            return presentationContact.media.featured_image.src;
          } else {
            return this.createDummyImgFormat(nameContact);
          }
        } else {
          return this.createDummyImgFormat(nameContact);
        }
      } else {
        return this.createDummyImgFormat(nameContact);
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD MMM YYYY";
      let dateParsed = moment.utc(String(date)).format(format);
      return dateParsed;
    },
    dateFormatPresentation(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD MMM YYYY";
      let dateParsed = moment.utc(String(date)).format(format);
      return dateParsed;
    }
  }
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
  letter-spacing: 0.008em;
}
.title-presentation {
  padding-top: 4px;
  padding-bottom: 4px;
}

.title-presentation p {
  font-size: 26px;
  font-weight: 500;
}

.container-img {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.informacion-principal {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
  align-items: flex-start;
  margin-bottom: 25px;
}
.informacion-inmobilaria {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 3.5rem;
  grid-row-end: 2;
  grid-row: span 2 / span 2;
  grid-column: span 2 / span 2;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.informacion-expositor {
  grid-row-end: 1;
  grid-column-start: 3;
  min-width: 300px;
  border-radius: 5px;
  padding-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.my-text-primary {
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #111827;
}
.contenedor-grids-informacion {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  line-height: 1.5rem;
  font-size: 0.875rem;
  margin-top: 1.5rem;
}
.contenedor-creado {
  padding-right: 1rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  display: flex;
  gap: 7px;
}
.text-fecha {
  color: #374151;
}
.contenedor-realizado {
  padding-left: 1rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  display: flex;
  gap: 7px;
}
.contenedor-from {
  padding-right: 1rem;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  line-height: 1.5rem;
}
.contenedor-to {
  padding-left: 1rem;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  line-height: 1.5rem;
}
.my-text-secondary {
  color: #111827;
  font-size: 0.875rem;
  font-weight: 600;
}

.item-informacion-inmobilaria {
  margin-top: 0.5rem;
  color: #6b7280;
}

.my-text-sub-secondary {
  color: #111827;
  font-weight: 500;
}

.contenedor-expositor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.informacion-nombre {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  /*flex: 1 1 auto;*/
}
.informacion-nombre .my-text-secondary {
  line-height: 1.5rem;
}
.informacion-nombre .my-text-primary {
  margin-top: 0.25rem;
}
.informacion-expositor .item-1 {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  column-gap: 1rem;
  width: 100%;
  color: #6b7280;
}
.item-informacion-expositor {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  display: flex;
  column-gap: 1rem;
  width: 100%;
  color: #6b7280;
}
.informacion-asistencia-expositores {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.informacion-descripcion {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
}
.body-descripcion {
  margin-top: 0.5rem;
  color: #374151;
  letter-spacing: 0.025em;
}
</style>
