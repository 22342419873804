<template>
  <v-card
    min-width="280px"
    max-width="300px"
    elevation="2"
    class="my-positions"
  >
    <v-img
      v-if="presentation.image && presentation.image !== ''"
      :src="presentation.image"
      height="200px"
    ></v-img>
    <v-img
      v-else
      src="../../../public/img/no_img_presentation.png"
      height="200px"
    ></v-img>
    <v-card-title class="header-text-stat">{{
      presentation.title
    }}</v-card-title>
    <v-card-subtitle class="text--primary pb-0">
      <div class="d-flex">
        INM •
        {{ presentation.to_real_estate_group_name }}
      </div>
      <div class="d-flex">
        DES •
        {{ presentation.real_state_development_name }}
      </div>
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <!--<div class="text--secondary">Expositor: {{ presentation.expositor }}</div>-->
      <div>{{ presentation.date | dateFormat(true) }}</div>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="false" color="primary" text @click="emitEditPresentation()">
        Editar
      </v-btn>
      <v-btn color="primary" text @click="emitWatchPresentation()">
        Ver Más
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text class="pb-0">
          <p class="text--primary mb-0">
            {{ presentation.comment }}
          </p>
        </v-card-text>
        <v-list class="transparent py-0">
          <v-list-item>
            <v-list-item-subtitle>Asistencias:</v-list-item-subtitle>

            <v-list-item-icon>
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-subtitle class="text-right">
              {{ totalAsistencias }}
            </v-list-item-subtitle>
          </v-list-item>
          <!-- Para los expositores ayudantes -->
          <!--<v-list-item>
            <v-list-item-subtitle>Expositores:</v-list-item-subtitle>

            <v-list-item-icon>
              <v-icon>mdi-presentation</v-icon>
            </v-list-item-icon>

            <v-list-item-subtitle class="text-right">
              {{ totalExpositores }}
            </v-list-item-subtitle>
          </v-list-item>-->
          <v-list-item>
            <v-btn
              block
              text
              color="red darken-4"
              @click="emitDeletePresentation()"
            >
              <v-icon left>mdi-delete</v-icon>
              Eliminar
            </v-btn>
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from "moment";
moment.locale("es");
export default {
  data() {
    return {
      show: false,
      id: 4,
      nombre: "",
      expositor: "",
      descripcion: "",
      fecha: "22 de Sep de 2022",
      imagen: "",
      inmobiliaria: ""
    };
  },
  props: {
    presentation: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {
    emitEditPresentation() {
      //this.$router.push({ name: "BrokerPresentationDetailView" });
      this.$emit("open");
    },
    emitWatchPresentation() {
      this.$emit("openWatch", this.presentation._id);
    },
    emitDeletePresentation() {
      this.$emit("openDelete", this.presentation._id);
    },
    pluralOrSingularMethod(length) {
      if (length === 1) {
        return 1;
      } else {
        return 2;
      }
    }
  },
  computed: {
    totalAsistencias() {
      if (this.presentation.participating) {
        //Obtenemos cuandos asistentes hubo
        const lengthAsistentes = this.presentation.participating.length;
        //Averiguamos si fueron mas de 1
        const typeMsgLength = this.pluralOrSingularMethod(lengthAsistentes);
        let msg = `${lengthAsistentes} `;
        msg += typeMsgLength === 1 ? "asistencia" : "asistencias";
        return msg;
      } else {
        return "";
      }
    },
    totalExpositores() {
      if (this.presentation.expositores) {
        //Obtenemos cuantos expositores hubo
        const lengthExpositores = this.presentation.expositores.length;
        // Averiguamos si fueron mas de 1
        const typeMsgLength = this.pluralOrSingularMethod(lengthExpositores);
        let msg = `${lengthExpositores} `;
        msg += typeMsgLength === 1 ? "expositor" : "expositores";
        return msg;
      } else {
        return "";
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD MMM YYYY";
      let dateParsed = moment.utc(String(date)).format(format);
      return dateParsed;
    }
  }
};
</script>
<style scoped>
.my-text-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
  margin: 0;
}
.header-text-stat {
  font-size: 18px;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.87);
  line-height: 1.5rem;
}
</style>
