<template>
  <div>
    <div v-if="loadingPresentation">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>Cargando presentaciones...</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div
      v-else-if="
        !loadingPresentation && allPresentation && allPresentation.length <= 0
      "
    >
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container>
          <v-img
            contain
            max-height="425"
            src="../../../public/img/undraw_No_data_re_kwbl.png"
          >
          </v-img>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <div class="title">
                Vaya, parece que no hay presentaciones por mostrar.
              </div>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div class="subtitle-2 grey--text">
                No se encontraron presentaciones, intenta recargando o agregando
                una presentación.
              </div>
            </v-col>
          </v-row>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              text
              @click="showAddContactModalF()"
            >
              Agregar presentación
              <v-icon right dark>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-title>
        </v-container>
      </v-card>
    </div>
    <v-card
      v-if="
        allPresentation && allPresentation.length > 0 && !loadingPresentation
      "
      class="pa-2"
      elevation="0"
    >
      <div>
        <v-row v-if="false" justify="center">
          <v-col cols="12">
            <v-sheet elevation="0" rounded>
              <v-sheet class="pa-3 primary text-right" dark rounded="t">
                <v-card color="transparent" elevation="0">
                  <div>
                    <h2 class="text-left text-h5">
                      Sobre este mes
                    </h2>
                  </div>
                </v-card>
              </v-sheet>

              <div class="my-contenedor-stats">
                <v-card elevation="0">
                  <v-list-item two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-presentation
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Total de presentaciones
                      </v-list-item-subtitle>
                      <v-list-item-title
                        class="text-h5 font-weight-medium mb-1"
                      >
                        1,200
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card elevation="0">
                  <v-list-item two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-fire
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Asesor con mas presentaciones
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-h5 font-weight-medium">
                        Luis Ángel Balam
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card elevation="0">
                  <v-list-item two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-select-all
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Promedio de presentaciones al dia
                      </v-list-item-subtitle>
                      <v-list-item-title
                        class="text-h5 font-weight-medium mb-1"
                      >
                        6.7%
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-sheet elevation="0" rounded>
              <v-sheet class="px-3 pt-3 text-right" rounded="t">
                <v-card color="transparent" elevation="0">
                  <div class="ml-5">
                    <h4 class="text-left text-subtitle-1 font-weight-medium">
                      Sobre este mes
                    </h4>
                  </div>
                </v-card>
              </v-sheet>

              <div class="my-contenedor-stats">
                <v-card class="" elevation="0" outlined>
                  <v-list-item class="pa-6" two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-presentation
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="header-text-stat">
                        Total de presentaciones en el mes
                      </v-list-item-title>
                      <v-list-item-title class="body-text-stat">
                        {{ this.stats.totalPresentationInMonth }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card class="" elevation="0" outlined>
                  <v-list-item class="pa-6" two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-fire
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="header-text-stat">
                        Asesor con mas presentaciones en el mes
                      </v-list-item-title>
                      <v-list-item-title class="body-text-stat">
                        {{ this.stats.nameBroker }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card class="" elevation="0" outlined>
                  <v-list-item class="pa-6" two-line>
                    <v-list-item-avatar
                      tile
                      size="40"
                      color="primary"
                      class="rounded"
                    >
                      <v-icon color="white">
                        mdi-select-all
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="header-text-stat">
                        Promedio de presentaciones al dia en el mes
                      </v-list-item-title>
                      <v-list-item-title class="body-text-stat">
                        {{ this.stats.avgPresentation }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <v-row justify="center">
                <v-col cols="7" v-if="false">
                  <v-card>
                    <v-sparkline
                      auto-draw
                      :auto-draw-duration="1000"
                      :labels="getDataSparklineOne.listBrokers"
                      :value="getDataSparklineOne.listTotalPresentationsBrokers"
                      :show-labels="true"
                      color="black"
                      line-width="1"
                      padding="24"
                    ></v-sparkline>
                  </v-card>
                </v-col>
                <v-col cols="7" v-if="false">
                  <v-card>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="chartSeriesComputed"
                      ></apexchart>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="10">
                  <v-card>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="450"
                        :options="chartOptionsComputed"
                        :series="chartSeriesComputed"
                      ></apexchart>
                    </div>
                    <v-row
                      no-gutters
                      justify="end"
                      class="px-3 py-2 text-caption"
                    >
                      <span class="text--secondary"
                        >Estas graficas se generan en base al mes actual</span
                      ></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined text @click="showAddContactModalF()">
            Agregar presentación
            <v-icon right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <div class="contenedor-tabs">
          <v-card elevation="0">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              grow
            >
              <v-tab key="tab1">Todas las presentaciones</v-tab>
              <v-tab key="tab2">Tus presentaciones</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="tab1">
                <div>
                  <v-list expand two-line>
                    <v-list-group
                      v-for="item in allPresentation"
                      :key="item._id"
                      no-action
                    >
                      <template v-slot:activator>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img :src="imgContact(item)"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.speaker.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.presentations.length }}
                              presentaciones
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item
                        v-for="presentation in item.presentations"
                        :key="presentation._id"
                        link
                      >
                        <v-list-item-avatar>
                          <v-img
                            v-if="
                              presentation.image && presentation.image !== ''
                            "
                            :src="presentation.image"
                          ></v-img>
                          <v-icon class="grey lighten-1" v-else dark>
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="presentation.title"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            {{ presentation.date | dateFormat(true) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            small
                            text
                            color="blue darken-4"
                            @click="openModalWatchPresentation(presentation)"
                          >
                            Ver más
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </div>
              </v-tab-item>
              <v-tab-item key="tab2">
                <div class="my-container-cards">
                  <div class="my-sub-container-cards">
                    <CardPresentation
                      v-for="presentation in allPresentationBroker"
                      :key="presentation._id"
                      :presentation="presentation"
                      @open="openModalEditPresentation(presentation)"
                      @openWatch="openModalWatchPresentation(presentation)"
                      @openDelete="openModalDeletePresentation(presentation)"
                    ></CardPresentation>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </div>
    </v-card>
    <add-presentation-modal-v2
      :propShowModal="propShowModal"
      @close="closeModalAddPresentation"
    ></add-presentation-modal-v2>
    <watch-presentation-modal
      :propsShowModalWatch="propsShowModalWatch"
      :presentationToWatchId="presentationToWatchId"
      @closeWatch="closeModalWatchPresentation"
    >
    </watch-presentation-modal>
    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <p class="text-h5 pt-4 px-6">
          ¿Seguro quieres eliminar la presentación?
        </p>

        <v-card-text>
          Estas seguro que quieres eliminar la presentación.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="closeModalDeletePresentation()">
            Cancelar
          </v-btn>

          <v-btn color="red darken-5" text @click="eliminarPresentacion()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es");
import { mapGetters, mapActions } from "vuex";
import CardPresentation from "./CardPresentation.vue";
import AddPresentationModalV2 from "./AddPresentationModalV2.vue";
import WatchPresentationModal from "./WatchPresentationModal.vue";
export default {
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
    };
    return {
      arrayPresentation: [
        {
          id: 1,
          nombre: "Presentación numero 1",
          inmobiliaria: "Capital 28",
          expositor: "Expositor 1",
          fecha: "22 de Sep de 2022",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          imagen: "https://picsum.photos/1920/1080?random",
          audiencia: [
            { name: "Audiencia 1 1", phone: "88447554", avatar: srcs[1] },
            { name: "Audiencia 2 1", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia 3 1", phone: "88447554", avatar: srcs[3] },
            { name: "Audiencia 4 1", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia 5 1", phone: "884475547441", avatar: srcs[4] },
            { name: "Audiencia 6 1", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia 7 1", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia 8 1", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2 1", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3 1", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4 1", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5 1", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6 1", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7 1", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8 1", phone: "884475541224", avatar: srcs[3] }
          ]
        },
        {
          id: 2,
          nombre: "Presentación 2",
          expositor: "Expositor 2",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          fecha: "22 de Sep de 2022",
          imagen: "https://picsum.photos/1920/1080?random",
          inmobiliaria: "Inmobiliaria 2",
          audiencia: [
            { name: "Audiencia num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Audiencia num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Audiencia num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Audiencia num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia num 8", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8", phone: "884475541224", avatar: srcs[3] }
          ]
        },
        {
          id: 3,
          nombre: "Presentación 3",
          expositor: "Expositor 3",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          fecha: "22 de Sep de 2022",
          imagen: "https://picsum.photos/1920/1080?random",
          inmobiliaria: "Inmobiliaria 3",
          audiencia: [
            { name: "Audiencia num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Audiencia num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Audiencia num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Audiencia num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Audiencia num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia num 8", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8", phone: "884475541224", avatar: srcs[3] }
          ]
        },
        {
          id: 4,
          nombre: "Presentación 4",
          expositor: "Expositor 4",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          fecha: "22 de Sep de 2022",
          imagen: "https://picsum.photos/1920/1080?random",
          inmobiliaria: "Inmobiliaria 4",
          audiencia: [
            { name: "Audiencia num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Expositor num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Expositor num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia num 8", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8", phone: "884475541224", avatar: srcs[3] }
          ]
        },
        {
          id: 5,
          nombre: "Presentación 5",
          expositor: "Expositor 5",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          fecha: "22 de Sep de 2022",
          imagen: "https://picsum.photos/1920/1080?random",
          inmobiliaria: "Inmobiliaria 5",
          audiencia: [
            { name: "Audiencia num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Expositor num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Expositor num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia num 8", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8", phone: "884475541224", avatar: srcs[3] }
          ]
        },
        {
          id: 6,
          nombre: "Presentación 6",
          expositor: "Mirse Hau",
          descripcion: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              similique dolorum ea vitae. Vel eveniet, cum perferendis laborum
              nihil, velit impedit, numquam atque dicta at ex minima repudiandae
              vero asperiores?`,
          fecha: "22 de Sep de 2022",
          imagen: "https://picsum.photos/1920/1080?random",
          inmobiliaria: "Inmobiliaria 6",
          audiencia: [
            { name: "Audiencia num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Expositor num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Expositor num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Expositor num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Expositor num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Audiencia num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Audiencia num 8", phone: "884475541224", avatar: srcs[3] }
          ],
          expositores: [
            { name: "Broker num 1", phone: "88447554", avatar: srcs[1] },
            { name: "Broker num 2", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 3", phone: "88447554", avatar: srcs[3] },
            { name: "Broker num 4", phone: "88447554", avatar: srcs[2] },
            { name: "Broker num 5", phone: "884475547441", avatar: srcs[4] },
            { name: "Broker num 6", phone: "884475541231", avatar: srcs[5] },
            { name: "Broker num 7", phone: "884475541231", avatar: srcs[1] },
            { name: "Broker num 8", phone: "884475541224", avatar: srcs[3] }
          ]
        }
        // Puedes agregar más objetos según lo necesites.
      ],
      presentationToEditObject: null,
      presentationToWatchId: null,
      presentationToEditId: null,
      loadingPresentation: true,
      propShowModal: false,
      propsShowModalWatch: false,
      tab: null,
      dialogDelete: false,
      items: ["Todas las presentaciones", "Tus presentaciones"],
      series: [
        {
          name: "PRODUCT A",
          data: [4, 2, 1, 1, 2, 3]
        },
        {
          name: "PRODUCT B",
          data: [1, 2, 2, 4, 1, 2]
        },
        {
          name: "PRODUCT C",
          data: [1, 1, 1, 1, 2, 1]
        },
        {
          name: "PRODUCT D",
          data: [2, 3, 2, 3, 2, 4]
        },
        {
          name: "PRODUCT E",
          data: [2, 3, 2, 3, 2, 4]
        },
        {
          name: "PRODUCT F",
          data: [2, 3, 2, 3, 2, 4]
        },
        {
          name: "PRODUCT G",
          data: [2, 3, 2, 3, 2, 4]
        }
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900
                }
              }
            }
          }
        },
        xaxis: {
          type: "datetime",
          categories: [
            "01/01/2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT"
          ]
        },
        legend: {
          position: "right",
          offsetY: 40,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: [
              "#3f8ffb",
              "#35e395",
              "#f8b01a",
              "#f74560",
              "#785dd0",
              "#f44336",
              "#94a3b8"
            ],
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          opacity: 1,
          colors: [
            "#3f8ffb",
            "#35e395",
            "#f8b01a",
            "#f74560",
            "#785dd0",
            "#f44336",
            "#94a3b8"
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      allPresentation: "presentation/allPresentation",
      allPresentationBroker: "presentation/allPresentationBroker",
      stats: "presentation/stats"
    }),
    chartOptionsComputed() {
      return {
        chart: {
          type: "bar",
          height: 450,
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            }
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900
                }
              }
            }
          }
        },
        xaxis: {
          type: "Asesor",
          categories: this.getBrokersPresentation().listBrokers
        },
        legend: {
          position: "right",
          offsetY: 40,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: [
              "#3f8ffb",
              "#35e395",
              "#f8b01a",
              "#f74560",
              "#785dd0",
              "#f44336",
              "#94a3b8"
            ],
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          opacity: 1,
          colors: [
            "#3f8ffb",
            "#35e395",
            "#f8b01a",
            "#f74560",
            "#785dd0",
            "#f44336",
            "#94a3b8"
          ]
        }
      };
    },
    chartSeriesComputed() {
      const series = [
        {
          name: "Zoom Brokers",
          data: [],
          id: 1
        },
        {
          name: "Presentación Agencia",
          data: [],
          id: 2
        },
        {
          name: "Tour Brokers",
          data: [],
          id: 3
        },
        {
          name: "Tour Broker Cliente",
          data: [],
          id: 4
        },
        {
          name: "Otro",
          data: [],
          id: 5
        }
      ];

      if (this.allPresentation && this.allPresentation.length > 0) {
        this.allPresentation.forEach(broker => {
          if (broker.speaker && broker.presentations) {
            let brokerPresentation_ZoomBrokers = 0;
            let brokerPresentation_PresentacionAgencia = 0;
            let brokerPresentation_TourBrokers = 0;
            let brokerPresentation_TourBrokerCli = 0;
            //let brokerPresentation_NuevoBrokers = 0;
            //let brokerPresentation_Cartera = 0;
            let brokerPresentation_Otro = 0;
            broker.presentations.forEach(brokerPresentation => {
              if (brokerPresentation.typePresentation === "Zoom Brokers") {
                brokerPresentation_ZoomBrokers++;
              } else if (
                brokerPresentation.typePresentation === "Presentación Agencia"
              ) {
                brokerPresentation_PresentacionAgencia++;
              } else if (
                brokerPresentation.typePresentation === "Tour Brokers"
              ) {
                brokerPresentation_TourBrokers++;
              } else if (
                brokerPresentation.typePresentation === "Tour Broker-Cli" ||
                brokerPresentation.typePresentation === "Tour Broker Cliente"
              ) {
                brokerPresentation_TourBrokerCli++;
              } else if (
                brokerPresentation.typePresentation === "Nuevo Brokers"
              ) {
                brokerPresentation_Otro++;
              } else if (brokerPresentation.typePresentation === "Cartera") {
                brokerPresentation_Otro++;
              } else {
                brokerPresentation_Otro++;
              }
            });
            series[0].data.push(brokerPresentation_ZoomBrokers);
            series[1].data.push(brokerPresentation_PresentacionAgencia);
            series[2].data.push(brokerPresentation_TourBrokers);
            series[3].data.push(brokerPresentation_TourBrokerCli);
            //series[4].data.push(brokerPresentation_NuevoBrokers);
            //series[5].data.push(brokerPresentation_Cartera);
            series[4].data.push(brokerPresentation_Otro);
          } else {
            return series;
          }
        });
        return series;
      } else {
        return series;
      }
    },
    getDataSparklineOne() {
      const listBrokers = [];
      const listTotalPresentationsBrokers = [];

      if (this.allPresentation && this.allPresentation.length > 0) {
        this.allPresentation.forEach(broker => {
          if (broker.speaker && broker.presentations) {
            listBrokers.push(broker.speaker.name);
            listTotalPresentationsBrokers.push(broker.presentations.length);
          } else {
            return null;
          }
        });
        return { listBrokers, listTotalPresentationsBrokers };
      } else {
        return null;
      }
    }
  },
  components: {
    CardPresentation,
    AddPresentationModalV2,
    WatchPresentationModal
  },
  async mounted() {
    this.loadingPresentation = true;
    await this.getAllPresentationByGroup();
    await this.getAllPresentationBroker();
    await this.getStatsPresentationByGroup();
    this.loadingPresentation = false;
  },
  methods: {
    ...mapActions({
      getAllPresentationByGroup: "presentation/getAllPresentationByGroup",
      getAllPresentationBroker: "presentation/getAllPresentationByBroker",
      getStatsPresentationByGroup: "presentation/getStatsPresentationByGroup",
      deletePresentation: "presentation/deletePresentation"
    }),
    imgContact(presentation) {
      const nameContact = presentation.speaker.name;
      if (presentation.presentation_contact_admin) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_admin,
          nameContact
        );
        return imagenContact;
      }
      if (presentation.presentation_contact_broker) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_broker,
          nameContact
        );
        return imagenContact;
      }
      if (presentation.presentation_contact_developer) {
        const imagenContact = this.validarImagen(
          presentation.presentation_contact_developer,
          nameContact
        );
        return imagenContact;
      }
      return this.createDummyImgFormat(nameContact);
    },
    validarImagen(presentationContact, nameContact) {
      if (presentationContact.media) {
        if (presentationContact.media.featured_image) {
          if (presentationContact.media.featured_image.src) {
            return presentationContact.media.featured_image.src;
          } else {
            return this.createDummyImgFormat(nameContact);
          }
        } else {
          return this.createDummyImgFormat(nameContact);
        }
      } else {
        return this.createDummyImgFormat(nameContact);
      }
    },

    showAddContactModalF() {
      this.propShowModal = true;
    },
    closeModalAddPresentation() {
      this.propShowModal = false;
      this.presentationToEditObject = null;
    },
    openModalEditPresentation(presentation) {
      this.presentationToEditObject = presentation;
      this.propShowModal = true;
    },
    openModalWatchPresentation(presentation) {
      this.presentationToWatchId = presentation._id;
      this.propsShowModalWatch = true;
    },
    closeModalWatchPresentation() {
      this.propsShowModalWatch = false;
      this.presentationToWatchId = null;
    },
    openModalDeletePresentation(presentation) {
      this.presentationToDeleteId = presentation._id;
      this.dialogDelete = true;
    },
    closeModalDeletePresentation() {
      this.dialogDelete = false;
      this.presentationToDeleteId = null;
    },
    eliminarPresentacion() {
      this.dialogDelete = false;
      this.deletePresentation(this.presentationToDeleteId);
    },
    getUserComment(commentObject) {
      let userComment = {};
      if (commentObject.contact_broker_id) {
        userComment = this.brokersComments.find(i => {
          return commentObject.contact_broker_id === i._id;
        });
      }
      if (commentObject.contact_admin_id) {
        userComment = this.adminsComments.find(i => {
          return commentObject.contact_admin_id === i._id;
        });
      }
      if (commentObject.contact_developer_id) {
        userComment = this.developersComments.find(i => {
          return commentObject.contact_developer_id === i._id;
        });
      }
      return userComment;
    },
    createDummyImgFormat(nameBroker = "C28") {
      let returnDummyImg =
        "https://dummyimage.com/600x400/2c344b/fff&text=" +
        nameBroker.substring(0, 2);
      return returnDummyImg;
    },
    getBrokersPresentation() {
      const listBrokers = [];
      const series = [
        {
          name: "Zoom Brokers",
          data: [],
          id: 1
        },
        {
          name: "Presentación Agencia",
          data: [],
          id: 2
        },
        {
          name: "Tour Brokers",
          data: [],
          id: 3
        },
        {
          name: "Tour Broker-Cli",
          data: [],
          id: 4
        },
        {
          name: "Otro",
          data: [],
          id: 5
        }
      ];

      if (this.allPresentation && this.allPresentation.length > 0) {
        this.allPresentation.forEach(broker => {
          if (broker.speaker && broker.presentations) {
            let brokerPresentation_ZoomBrokers = 0;
            let brokerPresentation_PresentacionAgencia = 0;
            let brokerPresentation_TourBrokers = 0;
            let brokerPresentation_TourBrokerCli = 0;
            //let brokerPresentation_NuevoBrokers = 0;
            //let brokerPresentation_Cartera = 0;
            let brokerPresentation_Otro = 0;

            listBrokers.push(broker.speaker.name);
            broker.presentations.forEach(brokerPresentation => {
              if (brokerPresentation.typePresentation === "Zoom Brokers") {
                brokerPresentation_ZoomBrokers++;
              } else if (
                brokerPresentation.typePresentation === "Presentación Agencia"
              ) {
                brokerPresentation_PresentacionAgencia++;
              } else if (
                brokerPresentation.typePresentation === "Tour Brokers"
              ) {
                brokerPresentation_TourBrokers++;
              } else if (
                brokerPresentation.typePresentation === "Tour Broker-Cli"
              ) {
                brokerPresentation_TourBrokerCli++;
              } else if (
                brokerPresentation.typePresentation === "Nuevo Brokers"
              ) {
                brokerPresentation_Otro++;
              } else if (brokerPresentation.typePresentation === "Cartera") {
                brokerPresentation_Otro++;
              } else {
                brokerPresentation_Otro++;
              }
            });
            series[0].data.push(brokerPresentation_ZoomBrokers);
            series[1].data.push(brokerPresentation_PresentacionAgencia);
            series[2].data.push(brokerPresentation_TourBrokers);
            series[3].data.push(brokerPresentation_TourBrokerCli);
            //series[4].data.push(brokerPresentation_NuevoBrokers);
            //series[5].data.push(brokerPresentation_Cartera);
            series[4].data.push(brokerPresentation_Otro);
          } else {
            return null;
          }
        });
        return { listBrokers, series };
      } else {
        return { listBrokers, series };
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD MMM YYYY";
      let dateParsed = moment.utc(String(date)).format(format);
      return dateParsed;
    }
  }
};
</script>

<style scoped>
.my-container-cards {
  padding: 40px 0px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.my-sub-container-cards {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  width: 100%;
  place-content: center;
  place-items: start;
}
@media (min-width: 768px) {
  .my-sub-container-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1180px) {
  .my-sub-container-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  .my-sub-container-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.header-text-stat {
  font-size: 16px;
  font-weight: 400;
  color: rgb(17, 24, 39);
  line-height: 1.5rem;
}
.body-text-stat {
  font-size: 22px;
  font-weight: 500;
  color: rgb(0, 45, 141);
  line-height: 2rem;
}
.my-contenedor-stats {
  /*pb-4 mx-5 flex-column d-sm-flex justify-space-around*/
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media (min-width: 640px) {
  .my-contenedor-stats {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media (min-width: 1124px) {
  .my-contenedor-stats {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.contenedor-tabs {
  max-width: 1500px;
  margin: auto auto;
}
</style>
