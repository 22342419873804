<template>
  <!-- Botón con el modal -->
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined text v-bind="attrs" v-on="on">
        Agregar Broker Externo
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-toolbar color="primary" class="white--text" flat>
        <v-toolbar-title> Nuevo Broker Externo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <external-broker-search-and-registration
          typeComponent="ExternalBroker"
          @setValidate="value => (valid = value)"
          @setData="value => (data = value)"
          @setCurrentScreen="value => (currentScreen = value)"
        />
        <br />

        <v-row align="center" justify="space-around" v-if="currentScreen">
          <v-btn
            color="primary"
            @click="save()"
            :loading="loadingSave"
            :disabled="!valid"
          >
            Registrar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    ExternalBrokerSearchAndRegistration: () =>
      import(
        "@/components/external-broker/ExternalBrokerSearchAndRegistration.vue"
      )
  },
  data() {
    return {
      otherProp: "ogeogeje",
      dialog: false,
      valid: false,
      currentScreen: false,
      data: null,
      loadingSave: false
    };
  },
  methods: {
    ...mapActions({
      createBrexExternalPresentation: "brex/createBrexExternalPresentation"
    }),
    close() {
      this.dialog = false;
      this.valid = false;
      this.data = null;
      this.loadingSave = false;
      this.currentScreen = false;
    },
    save() {
      this.loadingSave = true;
      let self = this;
      this.$snotify.async(
        "Registrando un Nuevo Broker Externo..",
        "Registrando...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              self.sendCreateLead(resolve, reject);
            }, 1000);
          })
      );
    },
    sendCreateLead(resolveNotify, rejectsNotify) {
      try {
        let self = this;
        this.createBrexExternalPresentation(self.generateDataSave(self.data))
          .then(newBrex => {
            setTimeout(() => {
              resolveNotify({
                title: "Broker Externo registrado",
                body: "El Broker Externo se ha registrado correctamente.",
                config: {
                  closeOnClick: true,
                  titleMaxLength: 30,
                  timeout: 4000
                }
              });
              //Aqui se pondra el codigo para emitir el objeto
              this.$emit("agregarBrokerExterno", newBrex);
            }, 500);
          })
          .catch(err => {
            this.loadingSave = false;
            throw err;
          })
          .finally(() => {
            this.loadingSave = false;
            self.close();
          });
      } catch (err) {
        this.loadingSave = false;
        setTimeout(
          () =>
            rejectsNotify({
              title: "Error!!!",
              body: "Ocurrio un problema al crear el Broker Externo",
              config: {
                closeOnClick: true
              }
            }),
          2000
        );
      }
    },
    generateDataSave(data) {
      return {
        name: data.name.trim(),
        email: data.email.replace(/ /g, ""),
        phone: (data.countrySelectedCode + data.phone).replace(/ /g, ""),
        type: "external",
        status: 1,
        real_estate_name: data.real_estate_name
      };
    }
  }
};
</script>
