<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      max-width="1000px"
      scrollable
      @click:outside="closeAddPresentationModal(false)"
    >
      <v-card class="">
        <v-card-text style="max-height: 600px;">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Inmobiliaria
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">
                Desarrollo
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">
                Lugar y Fecha
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 4" step="4">
                Audiencia
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 5" step="5">
                Evidencia
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 6" step="6">
                Comentario
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">¿A quién le presentaste?</h3>
                    <p class="text-body-2 text--secondary">
                      Escribe a que inmobiliaria le diste la presentación
                    </p>
                    <br />
                    <!--<v-select
                    :items="inmobiliarias"
                    item-text="name"
                    item-value="_id"
                    clearable
                    v-model="selectedInmobiliaria"
                    outlined
                    hint="Escoge o escribe a que inmobiliaria le hiciste la presentación de tu proyecto"
                    persistent-hint
                    label="Inmobiliaria"
                    @change="handleInmobiliariaChange"
                    :disabled="disabledSelectInmobiliaria"
                  ></v-select>
                  <br />-->
                    <v-text-field
                      label="Inmobiliaria"
                      outlined
                      v-model="selectedInmobiliariaField"
                      :disabled="disabledFieldInmobiliaria"
                    ></v-text-field>
                  </v-col>
                </v-card>
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="selectedInmobiliariaField == ''"
                    color="primary"
                    @click="e1 = 2"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">¿Qué les presentaste?</h3>
                    <p class="text-body-2 text--secondary">
                      Escoge o escribe en la parte de abajo el desarrollo que
                      presentaste
                    </p>
                    <br />
                    <!---<v-select
                    :items="this.allDesarrollos"
                    item-text="name"
                    item-value="name"
                    clearable
                    v-model="selectedDesarrollo"
                    outlined
                    persistent-hint
                    label="Desarrollo"
                    :disabled="disabledSelectDesarrollo"
                  ></v-select>-->
                    <v-menu offset-y max-height="300">
                      <template v-slot:activator="{ attrs, on }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          clearable
                          label="Desarrollo"
                          v-model="selectedDesarrolloField"
                        >
                        </v-text-field>
                      </template>

                      <v-list v-if="allDesarrollosFilter.length <= 0">
                        <v-list-item link>
                          <v-list-item-title
                            >Sin coincidencias</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                      <v-list v-else>
                        <v-list-item
                          v-for="item in allDesarrollosFilter"
                          :key="item.name"
                          link
                        >
                          <v-btn block text @click="selectDesarrollo(item)">
                            {{ item.name }}
                            <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <br />
                    <!--<v-text-field
                    label="Desarrollo"
                    outlined
                    v-model="selectedDesarrolloField"
                    :disabled="disabledFieldDesarrollo"
                  ></v-text-field>-->
                  </v-col>
                </v-card>
                <div class="d-flex">
                  <v-btn @click="e1 = 1">Anterior</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="
                      selectedDesarrolloField == '' && selectedDesarrollo == ''
                    "
                    color="primary"
                    @click="e1 = 3"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">¿Comó se lo presentaste?</h3>
                    <p class="text-body-2 text--secondary">
                      Selecciona como llevaste a cabo tu presentación
                    </p>
                    <br />
                    <v-select
                      :items="types_presentation"
                      v-model="select_type_presentation"
                      label="Modo de presentación"
                      outlined
                    ></v-select>
                    <br />
                    <h3 class="text-h6">¿Dónde se los presentaste?</h3>
                    <p class="text-body-2 text--secondary">
                      Escribe la ubicación donde se realizo la presentación
                    </p>
                    <br />
                    <v-text-field
                      label="Ubicación"
                      persistent-hint
                      :disabled="
                        select_type_presentation == 'Zoom Brokers'
                          ? true
                          : false
                      "
                      v-model="ubicacion"
                      outlined
                    ></v-text-field>
                    <br />

                    <h3 class="text-h6">¿Cuándo se los presentaste?</h3>
                    <p class="text-body-2 text--secondary">
                      Selecciona la fecha en la que realizaste la presentación
                    </p>
                    <br />
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          placeholder="Fecha"
                          outlined
                          readonly
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" color="primary" no-title>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-card>
                <div class="d-flex">
                  <v-btn @click="e1 = 2">Anterior</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="date === ''"
                    color="primary"
                    @click="e1 = 4"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">¿Quiénes asistieron?</h3>
                    <p class="text-body-2 text--secondary">
                      Selecciona a todos los brokers externos que asistieron a
                      la presentación. <br />
                      Si no están dados de alta, regístralos con el botón de
                      abajo
                    </p>
                    <br />
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <create-brex-component
                        @agregarBrokerExterno="agregarBrokerExterno"
                      ></create-brex-component>
                    </div>
                    <br />
                    <v-autocomplete
                      v-model="selectedAudiencia"
                      :disabled="isUpdating"
                      :items="brokerExternalList"
                      outlined
                      chips
                      color="primary"
                      label="Audiencia"
                      item-text="name"
                      item-value="_id"
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          <v-avatar left>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-avatar>
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content
                            v-text="data.item"
                          ></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar>
                            <v-icon large>mdi-account-circle</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.phone"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-card>

                <div class="d-flex">
                  <v-btn @click="e1 = 3">Anterior</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="selectedAudiencia.length === 0"
                    color="primary"
                    @click="e1 = 5"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">Sube tu evidencia</h3>
                    <p class="text-body-2 text--secondary">
                      Sube la imagen para la evidencia de tu presentación.
                    </p>
                    <br />
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-camera"
                      placeholder="Imagen"
                      v-model="newImage"
                      @change="changeImgPresentation"
                    ></v-file-input>
                    <br />
                    <v-col v-if="existeImagen" cols="12">
                      <v-img
                        v-if="existeImagen"
                        :src="urlImagen"
                        :key="urlImagen"
                        height="325"
                        class="grey darken-4"
                      ></v-img>
                    </v-col>
                  </v-col>
                </v-card>

                <div class="d-flex">
                  <v-btn @click="e1 = 4">Anterior</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!existeImagen"
                    color="primary"
                    @click="e1 = 6"
                  >
                    Siguiente
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="6">
                <v-card class="mb-12">
                  <v-col cols="12" sm="12">
                    <h3 class="text-h6">¿Quieres agregar un comentario?</h3>
                    <p class="text-body-2 text--secondary">
                      Escribe algún comentario u observación de tu presentación.
                    </p>
                    <br />
                    <v-text-field
                      label="Comentario"
                      persistent-hint
                      v-model="comentario"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-card>

                <div class="d-flex">
                  <v-btn @click="e1 = 5">Anterior</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="enviarPresentacion()">
                    Terminar
                  </v-btn>

                  <v-btn text @click="closeAddPresentationModal(false)">
                    Cancelar
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import createBrexComponent from "./components/createBrexComponent.vue";
export default {
  components: {
    createBrexComponent
  },
  data() {
    return {
      date: "",
      menu: false,
      e1: 1,
      inmobiliarias: [
        {
          _id: "13465",
          name: "Capital 28"
        },
        {
          _id: "456789",
          name: "Propiedades Cancún"
        },
        {
          _id: "789123",
          name: "Propiedades Tulum"
        }
      ],
      desarrollos: [
        {
          _id: "123456",
          name: "Aldea Coba"
        },
        {
          _id: "456789",
          name: "Aldea Zama"
        },
        {
          _id: "789123",
          name: "Aldea 28"
        }
      ],
      types_presentation: [
        "Zoom Brokers",
        "Presentación Agencia",
        "Tour Brokers",
        "Tour Broker Cliente"
      ],
      select_type_presentation: "",
      audiencia: [],
      selectedAudiencia: [],
      urlImagen: URL,
      existeImagen: false,
      isUpdating: false,
      presentation: null,
      //Vmodels de la inmobiliaria
      selectedInmobiliaria: null,
      selectedInmobiliariaField: "",
      //Vmodels del desarrollo
      selectedDesarrollo: "",
      selectedDesarrolloField: "",
      //Vmodel ubicacion
      ubicacion: "",
      //VModel del comentario
      comentario: "",
      //VModel de la imagen
      newImage: []
    };
  },
  props: {
    propShowModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      brokerExternalList: "presentation/brokerExternalList",
      allDesarrollos: "presentation/allDesarrollos"
    }),
    show: {
      get() {
        return this.propShowModal;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    },
    //Inmobiliaria
    disabledFieldInmobiliaria() {
      if (this.selectedInmobiliaria) {
        return true;
      } else {
        return false;
      }
    },
    disabledSelectInmobiliaria() {
      if (this.selectedInmobiliariaField !== "") {
        return true;
      } else {
        return false;
      }
    },
    disabledBotonDesarollo() {
      if (this.selectedDesarrollo == "" && this.selectedDesarrolloField == "") {
        return true;
      } else {
        return false;
      }
    },
    //Desarrollo
    disabledFieldDesarrollo() {
      if (this.selectedDesarrollo) {
        return true;
      } else {
        return false;
      }
    },
    disabledSelectDesarrollo() {
      if (this.selectedDesarrolloField !== "") {
        return true;
      } else {
        return false;
      }
    },
    allDesarrollosFilter() {
      if (this.selectedDesarrolloField) {
        let temp = this.allDesarrollos.filter(dt =>
          dt.name
            .toUpperCase()
            .includes(this.selectedDesarrolloField.toUpperCase())
        );

        return temp;
      } else {
        return this.allDesarrollos;
      }
    }
  },
  async mounted() {
    await this.getAllPresentationByGroup();
    await this.getBrokerExternalList();
    await this.getAllDesarrollosByGroup();
  },
  methods: {
    ...mapActions({
      addBrokerExternalToList: "presentation/addBrokerExternalToList",
      createNewPresentation: "presentation/createNewPresentation",
      getBrokerExternalList: "presentation/getBrokerExternalList",
      getAllPresentationByGroup: "presentation/getAllPresentationByGroup",
      getAllDesarrollosByGroup: "presentation/getAllDesarrollosByGroup",
      getAllPresentationBroker: "presentation/getAllPresentationByBroker",
      getStatsPresentationByGroup: "presentation/getStatsPresentationByGroup"
    }),
    closeAddPresentationModal() {
      this.show = false;
    },
    remove(item) {
      const index = this.selectedAudiencia.indexOf(item._id);
      if (index >= 0) this.selectedAudiencia.splice(index, 1);
    },
    changeImgPresentation(e) {
      if (e) {
        this.existeImagen = true;
        const data = URL.createObjectURL(e).toString();
        this.urlImagen = data;
        // Suponiendo que 'event' es un objeto File, esto debería funcionar como se espera.
      } else {
        URL.revokeObjectURL(this.urlImagen);
        this.existeImagen = false;
        this.urlImagen = "";
      }
    },

    async enviarPresentacion() {
      this.closeAddPresentationModal(false);
      this.loadingSave = true;
      const development = this.selectedDesarrolloField;
      /*if (this.selectedDesarrollo === "" || this.selectedDesarrollo === null) {
        development = this.selectedDesarrolloField;
      } else {
        development = this.selectedDesarrollo;
      }*/
      let self = this;
      this.$snotify.async(
        "Estamos añadiendo tu presentacion..",
        "Creando...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(async () => {
              const img = await this.subirImagen();
              this.presentation = {
                comment: this.comentario,
                location: this.ubicacion,
                date: this.date,
                development: development,
                toRealEstateGroupName: this.selectedInmobiliariaField,
                image: img,
                participating: this.selectedAudiencia,
                typePresentation: this.select_type_presentation
              };
              self.sendCreatePresentation(resolve, reject);
            }, 1000);
          })
      );
    },
    sendCreatePresentation(resolveNotify, rejectsNotify) {
      try {
        this.createNewPresentation(this.presentation)
          .then(() => {
            setTimeout(async () => {
              resolveNotify({
                title: "Presentación creada",
                body: "La presentación se ha creado correctamente.",
                config: {
                  closeOnClick: true,
                  titleMaxLength: 30,
                  timeout: 4000
                }
              });
              await this.getAllPresentationByGroup();
              await this.getAllPresentationBroker();
              await this.getStatsPresentationByGroup();
            }, 500);
          })
          .catch(err => {
            throw err;
          })
          .finally(() => {
            this.clearVModels();
          });
      } catch (err) {
        this.clearVModels();
        setTimeout(
          () =>
            rejectsNotify({
              title: "Ooops!:(",
              body: "Ocurrio un problema al crear tu presentación",
              config: {
                closeOnClick: true
              }
            }),
          2000
        );
      }
    },
    agregarBrokerExterno(brokerExterno) {
      if (brokerExterno) {
        const newBrokerExternal = {
          _id: brokerExterno._id,
          name: brokerExterno.name,
          phone: brokerExterno.email
        };
        this.addBrokerExternalToList(newBrokerExternal);
        this.selectedAudiencia.push(newBrokerExternal._id);
      }
    },
    async subirImagen() {
      //const actualData = this.$store.state.userData;
      const url =
        "https://api.cloudinary.com/v1_1/capital28-investments/upload";
      try {
        // Preparando imagen
        let formData = new FormData();
        formData.append("file", this.newImage);
        formData.append("upload_preset", "presentation_image");
        // subir imagen a cloudinary
        const data = await fetch(url, { method: "POST", body: formData });
        const json = await data.json();
        // Actuaizar url en la DB
        const image = json.secure_url;
        return image;
      } catch (e) {
        this.$snotify.error(`Ocurrio un erro al cambiar la imagen.`, `Error!`, {
          closeOnClick: true,
          timeout: 4000
        });
      }
    },
    clearVModels() {
      //Mandar al paso 1 en el step
      this.e1 = 1;
      //Inmobiliaria a la que se le presento
      this.selectedInmobiliaria = "";
      this.selectedInmobiliariaField = "";
      //Desarrollo que se presento
      this.selectedDesarrollo = "";
      this.selectedDesarrolloField = "";
      //Ubicación donde se realizo
      this.ubicacion = "";
      //Fecha de la presentación
      this.date = "";
      //Los brokers que asistieron
      this.selectedAudiencia = [];
      // Imagen
      URL.revokeObjectURL(this.urlImagen);
      this.existeImagen = false;
      this.urlImagen = "";
      this.newImage = [];
      //Comentario
      this.comentario = "";
    },
    selectDesarrollo(item) {
      if (item) {
        this.selectedDesarrolloField = item.name;
      }
    }
  }
};
</script>

<style scoped>
.label-input-text {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  margin-bottom: 0px;
}
</style>
